import { createRouter, createWebHistory } from "vue-router";
import SubscriptionPayment from "@/views/SubscriptionPayment.vue";
import { authTokenPresentGuard } from "./guards";
import HomeView from "@/views/HomeView.vue";

export enum RouteName {
  HOME_VIEW = "home-view",
  SUBSCRIPTION_PAYMENT = "subscription-payment",
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: RouteName.HOME_VIEW,
      component: HomeView,
    },
    {
      path: "/subscription-payment",
      name: RouteName.SUBSCRIPTION_PAYMENT,
      component: SubscriptionPayment,
      beforeEnter: authTokenPresentGuard,
    },
  ],
});

export default router;
