<script setup lang="ts">
import camelcaseKeys from "camelcase-keys";
import { axiosInstance } from "@/utils/api/axios-instance";
import { API } from "@/utils/api/api-paths";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import LoaderSpinner from "@/components/ui/LoaderSpinner.vue";

const route = useRoute();
const loading = ref(true);
const error = ref(false);

onMounted(async () => {
  const token = route.query.token as string;
  try {
    const res = await axiosInstance.post(API.SUBSCRIPTION_FINALIZE, { token });
    const data = camelcaseKeys(res.data, { deep: true });

    const paymentPageUrl = data.subscription.paymentPageUrl;
    window.location.href = paymentPageUrl;
  } catch (err) {
    console.error("Error on the /subscription/finalize API, ", err);
    error.value = true;
  }

  loading.value = false;
});
</script>

<template>
  <div class="subscription-payment">
    <div class="vaha-logo">
      <img src="@/assets/logos/vaha-logo.svg" alt="vaha logo" />
    </div>
    <div class="loader-container" v-if="loading">
      <LoaderSpinner />
    </div>
    <div v-if="error" class="error-cont">
      <div class="title">Unfortunately we have encountered an error while trying to finalise the payment.</div>
      <div class="text">
        Please tap again on the 'Send me payment link via email' button on your VAHA device. A new email will be sent to
        you and you'll be able to continue and finalise the payment for your subscription. Be aware that the link in the
        email we send you is only valid for 30 minutes from the moment you request it, then it expires and you'll have
        to request a new link.
      </div>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
.subscription-payment {
  height: 100vh;
}
.vaha-logo {
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 3em;
}
.vaha-logo img {
  width: 15rem;
}
.loader-container {
  position: relative;
  width: 100%;
}
.error-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--clr-red-3);
  width: 70%;
  margin: 0 auto;
  font-weight: bold;
}
.error-cont .title {
  font-size: 1.3em;
  margin-bottom: 1em;
  text-align: center;
}
.error-cont .text {
  text-align: center;
}

@media (--max-phone-width) {
  .vaha-logo img {
    width: 8rem;
  }
  .error-cont .title {
    font-size: 1.1em;
  }
  .error-cont .text {
    font-size: 0.9em;
  }
}
</style>
